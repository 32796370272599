// 要纯净一些，只是做初始化用
import { initAppQueryEnv, initAppLoginInfo } from '@/utils/common';

initAppQueryEnv();
initAppLoginInfo();

window.recaptchaOptions = {
  enterprise: true,
  nonce: process.env.CSP_NONCE_RECAPTCHA,
};

import('./bootstrap');
