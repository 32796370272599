import { parseUrlQuery } from './url';

export enum ModulePage {
  profile = 'profile',
  bundles = 'bundles',
  whales = 'whales',
  tokens = 'tokens',
  protocols = 'protocols',
  nfts = 'nfts',
  empty = '',
}

export const getSourceForGAByModulePage = (page: ModulePage) => {
  return (
    {
      [ModulePage.profile]: 'Profile TimeMachine',
      [ModulePage.bundles]: 'Bundles',
      [ModulePage.whales]: 'Whales',
      [ModulePage.tokens]: 'Tokens',
      [ModulePage.protocols]: 'Protocols',
      [ModulePage.nfts]: 'NFTs',
      [ModulePage.empty]: '',
    }[page] || ''
  );
};

export const getModuleFromLocation = () => {
  const pathname = window.location.pathname.substring(1).split('/')[0];
  if (!pathname) return ModulePage.empty;
  const Modules = [
    ModulePage.profile,
    ModulePage.bundles,
    ModulePage.whales,
    ModulePage.tokens,
    ModulePage.protocols,
    ModulePage.nfts,
  ];
  return Modules.find(m => m === pathname) ?? ModulePage.empty;
};

export enum BundleType {
  multi = 'Multi',
  oneOrZero = 'OneOrZero',
}

export const isMultiBundle = (addresses: string[]) => (addresses?.length || 0) > 1;

export const getBundleType = (addresses: string[]) =>
  isMultiBundle(addresses) ? BundleType.multi : BundleType.oneOrZero;

export const isPageVisible = () => document.visibilityState !== 'hidden';

let isInDebankAppEnv = false;

// 每次打开的时候加载 sessionStorage
export const initAppQueryEnv = () => {
  const { query } = parseUrlQuery(window.location.href);
  const pre = sessionStorage.getItem('inDebankApp');

  if ((query?.inDebankApp || pre) && !isInDebankAppEnv) {
    isInDebankAppEnv = true;
    sessionStorage.setItem('inDebankApp', 'true');
  }
};

export const isInDebankApp = () => isInDebankAppEnv;

export const cacheLoginInfo = {
  currentAddress: '',
  sessionId: '',
  walletType: '',
};

export const initAppLoginInfo = () => {
  const { query } = parseUrlQuery(window.location.href);
  const { currentAddress, sessionId, walletType } = query || {};
  if (currentAddress && sessionId) {
    sessionStorage.setItem('currentAddress', currentAddress);
    sessionStorage.setItem('sessionId', sessionId);
    sessionStorage.setItem('walletType', walletType);
  }
  const preCurrentAddress = sessionStorage.getItem('currentAddress');
  const preSessionId = sessionStorage.getItem('sessionId');
  const preWalletType = sessionStorage.getItem('walletType');
  if (preCurrentAddress && preSessionId) {
    cacheLoginInfo['currentAddress'] = preCurrentAddress;
    cacheLoginInfo['sessionId'] = preSessionId;
    cacheLoginInfo['walletType'] = preWalletType || '';
  }
};

// 添加noopener后无法共享sessionStorage
// https://developer.chrome.com/blog/deps-rems-89/#stop-cloning-sessionstorage-for-windows-opened-with-noopener
// 网页在debank app中打开需要sessionStorage共享登录信息
export const openNewWindow = (url?: string, target?: string, noreferrer?: boolean) => {
  const inDebankApp = isInDebankApp();
  const paramsStr = `${inDebankApp ? '' : 'noopener'}${!inDebankApp && noreferrer ? ',noreferrer' : ''}`;
  const newWindow = window.open(url, target, paramsStr);
  if (newWindow?.opener) {
    newWindow.opener = null;
  }
};

export type PopupProps = {
  url: string;
  targetWindowName?: string;
  width?: number;
  height?: number;
};

export const createPopup = ({ url, targetWindowName, height = 470, width = 550 }: PopupProps) => {
  const left = window.screenX + (window.outerWidth - width) / 2;
  const top = window.screenY + (window.outerHeight - height) / 2.5;
  const externalPopup = window.open(
    url,
    targetWindowName,
    `popup,width=${width},height=${height},left=${left},top=${top},status=0,location=0,menubar=0,toolbar=0`,
  );
  return externalPopup;
};
