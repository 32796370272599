import { omit } from 'lodash';
import querystring from 'query-string';

export const query2object = (query: string) => querystring.parse(query.trim());

/**
 * @description try to parse url, separate url and query
 */
export function parseUrlQuery(url: string) {
  let queryString = '';
  let query: Record<string, any> = {};

  [url, queryString] = url.split('?');
  queryString = queryString || '';

  query = query2object(queryString);

  const { pathname } = new URL(withHttp(url));
  const canoicalPath = parseUripath(pathname);

  return { url, canoicalPath, query, queryString };
}

export function integrateQueryToUrl(url: string, extQuery: Record<string, string>) {
  let { url: urlWithoutQuery, query } = parseUrlQuery(url);
  query = { ...query, ...extQuery };

  const queryStr2 = new URLSearchParams(query).toString();
  return `${urlWithoutQuery}${queryStr2 ? `?${queryStr2}` : ''}`;
}

export const omitQueryToUrl = (url: string, ...omitTarget: string[]) => {
  const { url: urlWithoutQuery, query } = parseUrlQuery(url);
  const newQuery = omit(query, omitTarget);
  const queryStr = new URLSearchParams(newQuery).toString();
  return `${urlWithoutQuery}${queryStr ? `?${queryStr}` : ''}`;
};

export function parseUripath(pathname = window.location.pathname) {
  return pathname.replace(/\/$/, '');
}

export function withHttp(url: string, defaultProtocol = 'https') {
  var pattern = /^((http|https|ftp|file|mailto):\/\/)/;
  let _url = url?.trim() || '';

  if (!pattern.test(_url)) {
    _url = `${defaultProtocol}://${_url}`;
  }
  return _url;
}

export function isDebankUrl(url: string): boolean {
  return /^(https?:\/\/)?debank.com/.test(url);
}

export function getUrlWithEllipsis(url?: string, maxLength: number = 26) {
  if (url && url.length > maxLength) {
    return url.slice(0, maxLength) + '...';
  }
  return url;
}
